import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import * as profileActions from "../actions/profile.actions";
import * as fromServices from "../../services";
import { SnackBarService } from "gematik-shared";

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: fromServices.ProfileService,
    private snackBarService: SnackBarService,
  ) {}

  loadProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LoadProfile),
      switchMap((payload: any) => {
        return this.profileService.getProfile().pipe(
          map((response) => profileActions.LoadProfileSuccess({ payload: response })),
          catchError((error) => of(profileActions.LoadProfileFail(error))),
        );
      }),
    );
  });

  updateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.UpdateProfile),
      switchMap((obj) => {
        return this.profileService.updateProfile(obj.payload).pipe(
          map((response) => {
            this.snackBarService.openSuccess("Successfully updated profile");
            return profileActions.UpdateProfileSuccess({ payload: response });
          }),
          catchError((error) => of(profileActions.UpdateProfileFail(error))),
        );
      }),
    );
  });

  loadAssignmentGroupIdentities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileActions.LoadAgis),
      switchMap((payload: any) => {
        return this.profileService.getAssignmentGroupIdentities().pipe(
          map((response) => profileActions.LoadAgisSuccess({ payload: response })),
          catchError((error) => of(profileActions.LoadAgisFail(error))),
        );
      }),
    );
  });
}
