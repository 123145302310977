import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromProfile from "../reducers/profile.reducer";

const getState = createFeatureSelector<fromProfile.State>(fromProfile.FEATURE_KEY);

export const getProfile = createSelector(
  getState,
  (state) => state.profile.entities[Object.keys(state.profile.entities)[0]],
);
export const getProfileLoading = createSelector(getState, (state) => state.profile.loading);
export const getProfileLoaded = createSelector(getState, (state) => state.profile.loaded);

const getAgiState = createSelector(getState, (state) => state.agis);
export const getAgis = createSelector(getAgiState, fromProfile.agiAdapter.getSelectors().selectAll);

export const getAgisLoaded = createSelector(getState, (state) => state.agis.loaded);
