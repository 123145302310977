import { GematikProfile } from "../../models/gematik-profile.model";
import * as fromProfile from "../actions/profile.actions";
import { Action, ActionCreator, createReducer, on, ReducerTypes } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { SuiteCrmAssignmentGroupIdentity } from "gematik-shared";

export const FEATURE_KEY = "profile";

export interface ProfileState extends EntityState<GematikProfile> {
  loading: boolean;
  loaded: boolean;
}

export interface AgiState extends EntityState<SuiteCrmAssignmentGroupIdentity> {
  loading: boolean;
  loaded: boolean;
}

export interface State {
  profile: ProfileState;
  agis: AgiState;
}

export const profileAdapter: EntityAdapter<GematikProfile> = createEntityAdapter<GematikProfile>();
export const agiAdapter: EntityAdapter<SuiteCrmAssignmentGroupIdentity> =
  createEntityAdapter<SuiteCrmAssignmentGroupIdentity>({
    sortComparer: (a: SuiteCrmAssignmentGroupIdentity, b: SuiteCrmAssignmentGroupIdentity) =>
      a.identityRole.localeCompare(b.identityRole),
  });

export const initialProfileState: ProfileState = profileAdapter.getInitialState({
  loading: false,
  loaded: false,
});
export const initialAgiState: AgiState = agiAdapter.getInitialState({
  loading: false,
  loaded: false,
});
export const initialState: State = {
  profile: initialProfileState,
  agis: initialAgiState,
};

export const profileReducers: ReducerTypes<State, ActionCreator[]>[] = [
  on(fromProfile.LoadProfile, (state) => ({
    ...state,
    profile: { ...state.profile, loading: true, loaded: false },
  })),
  on(fromProfile.LoadProfileSuccess, (state, { payload }) => ({
    ...state,
    profile: profileAdapter.addOne(payload, { ...state.profile, loading: false, loaded: true }),
  })),
  on(fromProfile.LoadProfileFail, (state) => ({
    ...state,
    profile: { ...state.profile, loading: false, loaded: false },
  })),
  on(fromProfile.UpdateProfile, (state) => ({
    ...state,
    profile: { ...state.profile, loading: true, loaded: false },
  })),
  on(fromProfile.UpdateProfileSuccess, (state, { payload }) => ({
    ...state,
    profile: profileAdapter.updateOne(
      { id: payload.id, changes: payload },
      { ...state.profile, loading: false, loaded: true },
    ),
  })),
  on(fromProfile.UpdateProfileFail, (state) => ({
    ...state,
    profile: { ...state.profile, loading: false, loaded: false },
  })),
  on(fromProfile.LoadAgis, (state) => ({
    ...state,
    agis: { ...state.agis, loading: true, loaded: false },
  })),
  on(fromProfile.LoadAgisSuccess, (state, { payload }) => ({
    ...state,
    agis: agiAdapter.addMany(payload, { ...state.agis, loading: false, loaded: true }),
  })),
  on(fromProfile.LoadAgisFail, (state) => ({
    ...state,
    agis: { ...state.agis, loading: false, loaded: false },
  })),
];

const profileReducer = createReducer<State>(initialState, ...profileReducers);

export function reducer(state: State, action: Action) {
  return profileReducer(state, action);
}
