import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { GematikProfile } from "../../models/gematik-profile.model";
import { SuiteCrmAssignmentGroupIdentity } from "gematik-shared";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getProfile(): Observable<GematikProfile> {
    return this.http.get<GematikProfile>(`${this.env.backendUrl}/portal/profile`);
  }

  updateProfile(payload: GematikProfile): Observable<GematikProfile> {
    return this.http.post<GematikProfile>(`${this.env.backendUrl}/portal/profile`, payload);
  }

  getAssignmentGroupIdentities(): Observable<SuiteCrmAssignmentGroupIdentity[]> {
    return this.http.get<SuiteCrmAssignmentGroupIdentity[]>(
      `${this.env.backendUrl}/portal/profile/assignment-group-identities`,
    );
  }
}
