import { createAction, props } from "@ngrx/store";
import { GematikProfile } from "../../models/gematik-profile.model";
import { SuiteCrmAssignmentGroupIdentity } from "gematik-shared";

export enum ProfileActions {
  LOAD_PROFILE = "[Profile] Load Profile",
  LOAD_PROFILE_SUCCESS = "[Profile] Load Profile Success",
  LOAD_PROFILE_FAIL = "[Profile] Load Profile Fail",
  UPDATE_PROFILE = "[Profile] Update Profile",
  UPDATE_PROFILE_SUCCESS = "[Profile] Update Profile Success",
  UPDATE_PROFILE_FAIL = "[Profile] Update Profile Fail",
  LOAD_AGIS = "[Profile] Load Assignment Group Identities",
  LOAD_AGIS_SUCCESS = "[Profile] Load Assignment Group Identities Success",
  LOAD_AGIS_FAIL = "[Profile] Load Assignment Group Identities Fail",
  LOAD_AGI = "[Profile] Load Assignment Group Identity",
  LOAD_AGI_SUCCESS = "[Profile] Load Assignment Group Identity Success",
  LOAD_AGI_FAIL = "[Profile] Load Assignment Group Identity Fail",
}

export const LoadProfile = createAction(ProfileActions.LOAD_PROFILE);
export const LoadProfileSuccess = createAction(
  ProfileActions.LOAD_PROFILE_SUCCESS,
  props<{ payload: GematikProfile }>(),
);
export const LoadProfileFail = createAction(
  ProfileActions.LOAD_PROFILE_FAIL,
  props<{ payload: any }>(),
);
export const UpdateProfile = createAction(
  ProfileActions.UPDATE_PROFILE,
  props<{ payload: GematikProfile }>(),
);
export const UpdateProfileSuccess = createAction(
  ProfileActions.UPDATE_PROFILE_SUCCESS,
  props<{ payload: GematikProfile }>(),
);
export const UpdateProfileFail = createAction(
  ProfileActions.UPDATE_PROFILE_FAIL,
  props<{ payload: GematikProfile }>(),
);
export const LoadAgis = createAction(ProfileActions.LOAD_AGIS);
export const LoadAgisSuccess = createAction(
  ProfileActions.LOAD_AGIS_SUCCESS,
  props<{ payload: SuiteCrmAssignmentGroupIdentity[] }>(),
);
export const LoadAgisFail = createAction(ProfileActions.LOAD_AGIS_FAIL, props<{ payload: any }>());
