import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from "@angular/router";
import { filter, map } from "rxjs";

@Component({
  selector: "breadcrumb",
  styleUrls: ["breadcrumb.component.scss"],
  template: `
    <pre>breadcrumbs: {{ breadcrumbs | json }}</pre>
    <ul class="breadcrumb">
      <li
        data-testid="breadcrumb"
        *ngFor="let breadcrumb of breadcrumbs; let last = last"
        class="breadcrumb-item"
      >
        <a *ngIf="!last" routerLink="{{ breadcrumb.url }}">
          {{ breadcrumb.label }}
        </a>
        <span *ngIf="last">{{ breadcrumb.label }}</span>
      </li>
    </ul>
  `,
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    let breadcrumb: Breadcrumb = {
      label: "Home",
      url: "",
    };
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        let root: ActivatedRoute = this.route.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
        const index = this.breadcrumbs.findIndex((b) => b.label === breadcrumb.label);
        if (index === -1) {
          this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];
        }
      });
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = "breadcrumb";

    //get the child routes
    let children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map((segment) => segment.path).join("/");

      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumb: Breadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        url: url,
      };

      const index = breadcrumbs.findIndex((b) => b.label === breadcrumb.label);
      if (index === -1) {
        breadcrumbs.push(breadcrumb);
      }

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
}
